import React, {FC} from 'react';
import {useAuthUser} from '@yakoffice/yakoffice-firebase';
import {Route, Routes, useParams} from 'react-router-dom';
import Builds from '../../../views/builds/builds';
import Build from '../../../views/build/build';
import {BuildsRouteItems} from "../../routes";
import {NoAccess} from "@yakoffice/shared-components";
import {YakofficeHeader} from "@yakoffice/yakoffice-header";


export const BuildsIndex: FC = () => {

    const {projectId} = useParams<{ projectId: string }>()
    const authUser = useAuthUser();

    return <YakofficeHeader hostedInPortal={false}>
        {authUser?.isAuthorisedForHostedToolProject(projectId)
            ? <Routes>
                <Route path={""} element={<Builds/>}/>
                <Route path={BuildsRouteItems.BuildIdParam} element={<Build/>}/>
            </Routes>
            : <NoAccess/>}
    </YakofficeHeader>

}
