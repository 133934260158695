import '../styles/app.sass';
import React, {FC} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import * as routes from '../routes/routes';
import ProjectsIndex from '../routes/projects';
import {NotificationErrorBoundary} from '@yakoffice/notification-handler';
import {BuildsRouteItems} from "../routes/routes";
import {matchDeeply} from "@yakoffice/shared-components";

export const App: FC = () => {

    return <div id="app">
        <NotificationErrorBoundary errorTitle="Error Loading Yakoffice Header">
            <Routes>
                <Route path="/" element={<Navigate to={routes.link_BUILDS('ppk')}/>}/>
                <Route path={matchDeeply(BuildsRouteItems.Projects)} element={<ProjectsIndex/>}/>
            </Routes>
        </NotificationErrorBoundary>
    </div>
}
